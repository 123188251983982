import { FieldType } from "../graphQLTypes";
import { areEqualValues } from "./areEqualValues";
import {
  ICondition,
  ICheckIsFieldVisibleProps,
  IFieldValuesMap,
  IBuildFieldKeyProps,
  IFieldValue
} from "../types";
import { buildFieldKey, parseFieldKey } from "./buildFieldKey";

export const checkConditionValue = (
  value: IFieldValue,
  conditionValue: IFieldValue,
  conditionType: FieldType
): boolean => {
  if (!value && value !== false) {
    return false;
  }
  if (conditionType === FieldType.INTEGER) {
    return Number(value) === Number(conditionValue);
  }
  if (conditionType === FieldType.COUNTRY) {
    return value.id === conditionValue;
  }
  if (
    conditionType === FieldType.ADDRESS ||
    conditionType === FieldType.COMPANY
  ) {
    return Boolean(value.country?.id === conditionValue);
  }
  if (
    conditionType === FieldType.SELECT &&
    Array.isArray(value) &&
    Array.isArray(conditionValue)
  ) {
    return Boolean(areEqualValues(value.sort(), conditionValue.sort()));
  }
  return value === conditionValue;
};

export const checkConditions = (
  conditions: ICondition[][],
  fieldValuesMap: IFieldValuesMap
): boolean => {
  if (!conditions.length) {
    return true;
  }
  return conditions.some((conditionList: ICondition[]) =>
    conditionList.every((condition: ICondition) => {
      const value = fieldValuesMap[condition.fieldId];
      return checkConditionValue(value, condition.value, condition.type);
    })
  );
};

export interface ICheckIsQuestionVisible {
  conditions: ICondition[][];
  fieldKey: string;
  value: IFieldValue;
  initialValue: boolean;
}

export const checkIsQuestionVisible = ({
  fieldKey,
  conditions,
  value,
  initialValue
}: ICheckIsQuestionVisible): boolean => {
  if (!conditions?.length) {
    return true;
  }

  return conditions.some((conditionList: ICondition[]) =>
    conditionList.every((condition: ICondition) => {
      const { fieldId }: IBuildFieldKeyProps = parseFieldKey(fieldKey);

      if (condition.fieldId === fieldId) {
        return checkConditionValue(value, condition.value, condition.type);
      }

      return initialValue;
    })
  );
};

export const checkIsFieldVisible = ({
  conditions,
  questionId,
  answerId,
  fieldKey,
  value,
  formValues,
  unregisteredFields = []
}: ICheckIsFieldVisibleProps): boolean => {
  if (!conditions.length) {
    return true;
  }

  return conditions.some((conditionList) =>
    conditionList.every((condition) => {
      const key = buildFieldKey({
        questionId,
        answerId,
        fieldId: condition.fieldId,
        type: condition.type,
        fieldType: condition.fieldType,
        isMulti: condition.isMulti
      });
      const fieldValue = fieldKey === key ? value : formValues[key];

      const isUnregistered = unregisteredFields?.includes(key);

      return isUnregistered
        ? false
        : checkConditionValue(fieldValue, condition.value, condition.type);
    })
  );
};
