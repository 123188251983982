import { AnswerSidebar } from "components/AnswerSidebar";
import { ButtonBack } from "components/ButtonBack";
import { AcceptableDocuments } from "components/common/AcceptableDocuments";
import { DocumentQuestionCard } from "components/DocumentQuestionCard";
import { Modal } from "components/Modal";
import { PageHeader } from "components/PageHeader";
import * as caseActions from "modules/case/actions";
import {
  takeCaseContractId,
  takeCaseId,
  takeConsentType,
  takeIsCaseUpdating,
  takeMyDocumentQuestions,
  takeOriginalConsentForm,
  takeUpdatedFieldIds
} from "modules/case/selectors";
import React, { useCallback, useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { BUTTON_TYPE, usePrevious } from "@security-watchdog/sw-ui-kit";
import { v4 as uuidv4 } from "uuid";
import { ROUTES } from "src/constants";
import {
  CandidateCaseFragment_consentForm,
  caseUpdateVariables,
  ConsentType,
  FieldValueInput
} from "src/graphQLTypes";
import {
  IAnswer,
  IFieldAnswer,
  IQuestion,
  IUploadFileProps,
  IDownloadProps,
  IPartialTroubleShootHistoryRecord
} from "src/types";
import { ConfirmModal } from "components/ConfirmModal";
import { IPageData } from "modules/case/types";
import { ContentLayout } from "components/ContentLayout";
import { Header as CommonHeader } from "components/Header";
import { useScrollToTop } from "src/hooks/useScrollToTop";
import { ScreenerAccess } from "containers/ScreenerAccess";
import { takeIsAllFormsDisabled } from "modules/screenerAccess/selectors";
import { takeIsAdminFlow } from "modules/main/selectors";
import {
  EntityNameEnum,
  EntityToDelete,
  IAnswerToDelete,
  IFileToDelete,
  isFileToDeleteEntity
} from "./types";
import * as s from "./styles";
import {
  downloadFile,
  getMultiUploadFieldValue,
  getUploadFieldValue
} from "src/utils";
import { isMultiUploadField } from "src/utils";
import { useUploadFilesToStorageMS } from "src/hooks/useUploadFilesToStorageMS";

const MyDocumentsComponent: React.FC = () => {
  const navigateTo = useNavigate();

  const dispatch: Dispatch = useDispatch();

  useScrollToTop();

  const contractId = useSelector(takeCaseContractId);

  const data: IPageData = useSelector(takeMyDocumentQuestions);

  const caseId: string = useSelector(takeCaseId);

  const consentType: ConsentType = useSelector(takeConsentType);

  const isAllFormsDisabled: boolean = useSelector(takeIsAllFormsDisabled);

  const isAdminFlow: boolean = useSelector(takeIsAdminFlow);

  const isCaseUpdating: boolean = useSelector(takeIsCaseUpdating);

  const prevIsCaseUpdating: boolean | undefined = usePrevious(isCaseUpdating);

  const originalConsentForm:
    | CandidateCaseFragment_consentForm
    | null
    | undefined = useSelector(takeOriginalConsentForm);

  const updatedFieldIds: string[] = useSelector(takeUpdatedFieldIds);

  const [isShowModal, setShowModal] = useState<boolean>(false);

  const [questions, setQuestions] = useState<IQuestion[]>(data.questions);

  const [viewedFields, setViewedFields] = useState<IFieldAnswer[] | null>(null);

  const [entityToDelete, setEntityToDelete] = useState<EntityToDelete | null>(
    null
  );

  const [troubleShootHistory, setTroubleShootHistory] = useState<
    IPartialTroubleShootHistoryRecord[]
  >([]);

  useEffect(() => {
    if (!isCaseUpdating && prevIsCaseUpdating) {
      dispatch(
        caseActions.saveUploadFilesHistory.started({
          history: troubleShootHistory,
          caseId
        })
      );
      setTroubleShootHistory([]);
    }
  }, [
    caseId,
    dispatch,
    isCaseUpdating,
    prevIsCaseUpdating,
    troubleShootHistory,
    updatedFieldIds.length
  ]);

  useEffect(() => {
    setQuestions(data.questions);
  }, [data.questions]);

  const handleCloseSidebar = useCallback(() => setViewedFields(null), []);

  const handleShowAnswer = useCallback(
    (fields: IFieldAnswer[]) => (): void => setViewedFields(fields),
    []
  );

  const goToBack = useCallback(
    () => navigateTo({ pathname: ROUTES.ROOT, search: window.location.search }),
    [navigateTo]
  );

  const toggleShowModal = useCallback(
    () => setShowModal((prevIsShowModal: boolean) => !prevIsShowModal),
    []
  );

  const handleAddAnswer = useCallback(
    (fields: IFieldAnswer[], questionId: string) => {
      setQuestions((prevQuestions: IQuestion[]) =>
        prevQuestions.map((question: IQuestion) => {
          if (question.questionId === questionId) {
            return {
              ...question,
              answers: [
                ...question.answers,
                {
                  index: question.answers.length,
                  answerId: uuidv4(),
                  isAnswered: false,
                  fields,
                  isTemplateAnswer: true
                }
              ]
            };
          }
          return question;
        })
      );
    },
    []
  );

  const handleDeleteAnswer = useCallback(
    ({ history, questionId, answerId, isAnswered }: IAnswerToDelete) => {
      setTroubleShootHistory(history);

      if (isAnswered) {
        const caseInput: caseUpdateVariables = {
          files: [],
          input: {
            consentGiven: false,
            caseId,
            answers: [
              {
                questionId,
                answers: [
                  {
                    id: answerId,
                    fields: []
                  }
                ]
              }
            ]
          }
        };
        dispatch(
          caseActions.caseUpdate.started({
            caseInput,
            updatedFieldId: answerId
          })
        );
      } else {
        setQuestions((prevQuestions: IQuestion[]) =>
          prevQuestions.map((question: IQuestion) => {
            if (question.questionId === questionId) {
              return {
                ...question,
                answers: question.answers.filter(
                  (answer: IAnswer) => answer.answerId !== answerId
                )
              };
            }
            return question;
          })
        );
      }
    },
    [caseId, dispatch]
  );

  const downloadDeclarationFiles = useCallback(async () => {
    if (originalConsentForm) {
      await downloadFile(originalConsentForm);
    }
  }, [originalConsentForm]);

  const { uploadFile, isLoading } = useUploadFilesToStorageMS({ contractId });

  const handlerUploadFile = useCallback(
    async ({
      files,
      questionId,
      answerId,
      fieldId,
      fieldType,
      attachments,
      history,
      isAnswered
    }: IUploadFileProps) => {
      if (isLoading || Boolean(updatedFieldIds.length)) {
        return;
      }

      const isMultiUpload = isMultiUploadField(fieldType);
      let fieldValue: FieldValueInput | null;

      if (isMultiUpload) {
        fieldValue = getMultiUploadFieldValue(fieldId, files, attachments);
      } else {
        const uploadedFile = await uploadFile(files[0]);
        fieldValue = uploadedFile
          ? getUploadFieldValue(fieldId, uploadedFile)
          : null;
      }

      if (fieldValue) {
        const caseInput: caseUpdateVariables = {
          files,
          input: {
            consentGiven: false,
            caseId,
            answers: [
              {
                questionId,
                answers: [
                  {
                    ...(isAnswered ? { id: answerId } : {}),
                    fields: [fieldValue]
                  }
                ]
              }
            ]
          }
        };

        dispatch(
          caseActions.caseUpdate.started({
            caseInput,
            updatedFieldId: `${fieldId}${answerId}`
          })
        );
      }

      setTroubleShootHistory(history);
    },
    [caseId, dispatch, uploadFile, isLoading, updatedFieldIds]
  );

  const handleDeleteFile = useCallback(
    ({
      questionId,
      answerId,
      fieldId,
      fieldType,
      fieldValue,
      history
    }: IFileToDelete) => {
      const field: FieldValueInput = isMultiUploadField(fieldType)
        ? {
            fieldId,
            uploadIndexes: [-1],
            attachmentValues: (fieldValue || []).map(
              ({ fileName, id, uploadedBy, uploadDate, downloadUrl }) => ({
                fileName,
                id,
                uploadedBy,
                uploadDate,
                downloadUrl
              })
            )
          }
        : { fieldId, uploadIndex: -1 };

      const caseInput: caseUpdateVariables = {
        files: [],
        input: {
          consentGiven: false,
          caseId,
          answers: [
            {
              questionId,
              answers: [
                {
                  id: answerId,
                  fields: [field]
                }
              ]
            }
          ]
        }
      };
      dispatch(
        caseActions.caseUpdate.started({
          caseInput,
          updatedFieldId: `${fieldId}${answerId}`
        })
      );
      setTroubleShootHistory(history);
    },
    [caseId, dispatch]
  );

  const handlerUploadConsentForm = useCallback(
    ({ files, answerId, fieldId }: IUploadFileProps) => {
      const caseInput: caseUpdateVariables = {
        files,
        input: {
          consentGiven: false,
          caseId,
          answers: [],
          consentFormUploadIndex: 0
        }
      };
      dispatch(
        caseActions.caseUpdate.started({
          caseInput,
          updatedFieldId: `${fieldId}${answerId}`
        })
      );
    },
    [caseId, dispatch]
  );

  const handleDeleteConsentForm = useCallback(
    ({ answerId, fieldId }: IFileToDelete) => {
      const caseInput: caseUpdateVariables = {
        files: [],
        input: {
          consentGiven: false,
          caseId,
          answers: [],
          consentFormUploadIndex: -1
        }
      };
      dispatch(
        caseActions.caseUpdate.started({
          caseInput,
          updatedFieldId: `${fieldId}${answerId}`
        })
      );
    },
    [caseId, dispatch]
  );

  const handleCloseConfirmModal = useCallback(
    () => setEntityToDelete(null),
    []
  );

  const handleConfirmDeleteEntity = useCallback(() => {
    const isAnswerDeleting =
      entityToDelete && entityToDelete.entityName === EntityNameEnum.ANSWER;
    const isFileDeleting =
      entityToDelete && entityToDelete.entityName === EntityNameEnum.FILE;
    const isConsentFormDeleting =
      isFileDeleting && entityToDelete.isConsentForm;

    handleCloseConfirmModal();

    if (isAnswerDeleting) {
      return handleDeleteAnswer(entityToDelete?.entity);
    }

    if (isConsentFormDeleting && isFileToDeleteEntity(entityToDelete?.entity)) {
      return handleDeleteConsentForm(entityToDelete?.entity);
    }

    if (isFileDeleting && isFileToDeleteEntity(entityToDelete?.entity)) {
      return handleDeleteFile(entityToDelete?.entity);
    }
  }, [
    entityToDelete,
    handleCloseConfirmModal,
    handleDeleteAnswer,
    handleDeleteConsentForm,
    handleDeleteFile
  ]);

  const handleDeleteAnswerClick = useCallback(
    (answerToDelete: IAnswerToDelete) => (): void => {
      setEntityToDelete({
        entityName: EntityNameEnum.ANSWER,
        entity: answerToDelete
      });
    },
    []
  );

  const handleDeleteFileClick = useCallback(
    (isConsentForm: boolean) =>
      (fileToDelete: IFileToDelete): void => {
        setEntityToDelete({
          isConsentForm,
          entityName: EntityNameEnum.FILE,
          entity: fileToDelete
        });
      },
    []
  );

  const handleDownloadFile = useCallback(
    async (data: IDownloadProps) =>
      // todo: IDownloadProps should be as attachment, and in field we have acces to the attachemnt from the field Value
      await downloadFile({
        fileName: data.fileName,
        downloadUrl: data.downloadUrl,
        id: "",
        uploadedBy: "",
        uploadDate: 0,
        __typename: "Attachment"
      }),
    []
  );

  const isCompleteButtonDisabled = useMemo((): boolean => {
    for (let i = 0; i < questions.length; i++) {
      if (!questions[i].completed) {
        return true;
      }
    }

    return !!updatedFieldIds.length;
  }, [updatedFieldIds, questions]);

  const getIsDeleteFileDisabled = (isConsentFormQuestion?: boolean): boolean =>
    isAdminFlow ? Boolean(isConsentFormQuestion) : isAllFormsDisabled;

  return (
    <>
      <CommonHeader />

      <ScreenerAccess />

      <ContentLayout>
        <s.Container>
          <ButtonBack handlerClick={goToBack}>Back</ButtonBack>
          <PageHeader
            title="My documents"
            description="Below is a summary of the paperwork required for your screening. If not
            yet attached, please add these documents here in order to complete your
            application."
          />
          <s.ContainerButtons>
            <s.CustomButton
              buttonType={BUTTON_TYPE.Link}
              onClick={toggleShowModal}
            >
              <s.ButtonText>See list of acceptable documents</s.ButtonText>
            </s.CustomButton>
            {consentType === ConsentType.WET_SIGNATURE && (
              <s.CustomButton
                buttonType={BUTTON_TYPE.Link}
                onClick={downloadDeclarationFiles}
              >
                <s.ButtonText>Declaration of Authority form</s.ButtonText>
              </s.CustomButton>
            )}
          </s.ContainerButtons>
          <s.Content>
            {questions
              .filter((q) => q.isVisible)
              .map((question: IQuestion) => (
                <DocumentQuestionCard
                  isDisabled={isAllFormsDisabled}
                  isDeleteFileDisabled={getIsDeleteFileDisabled(
                    question.isConsentFormQuestion
                  )}
                  key={question.questionId}
                  question={question}
                  updatedFieldIds={updatedFieldIds}
                  onUploadFile={
                    question.isConsentFormQuestion
                      ? handlerUploadConsentForm
                      : handlerUploadFile
                  }
                  onDeleteFile={handleDeleteFileClick(
                    Boolean(question.isConsentFormQuestion)
                  )}
                  onShowAnswer={handleShowAnswer}
                  onAddEntry={handleAddAnswer}
                  onDeleteAnswer={handleDeleteAnswerClick}
                  onDownloadFile={handleDownloadFile}
                />
              ))}
          </s.Content>
          <s.CustomCompleteButton
            isDisabled={isCompleteButtonDisabled || isAllFormsDisabled}
            onClick={goToBack}
            buttonType={BUTTON_TYPE.Primary}
            isLoading={!!updatedFieldIds.length}
          >
            Submit
          </s.CustomCompleteButton>
          <AnswerSidebar
            title="Full answer"
            isOpened={Boolean(viewedFields)}
            fields={viewedFields || []}
            onClose={handleCloseSidebar}
          />
          {isShowModal && (
            <Modal
              isShowModal={isShowModal}
              onClose={toggleShowModal}
              title="List of acceptable documents"
              actionButtons={
                <s.CustomLink to={`${ROUTES.HELP}${window.location.search}`}>
                  Contact Support
                </s.CustomLink>
              }
            >
              <AcceptableDocuments />
            </Modal>
          )}
          <ConfirmModal
            isShowModal={Boolean(entityToDelete)}
            primaryBtnText={`Remove ${entityToDelete?.entityName}`}
            confirmAction={handleConfirmDeleteEntity}
            onModalClose={handleCloseConfirmModal}
          >
            {`Are you sure you want to remove this ${entityToDelete?.entityName}?`}
          </ConfirmModal>
        </s.Container>
      </ContentLayout>
    </>
  );
};

export const MyDocuments = React.memo(MyDocumentsComponent);

export default MyDocuments;
