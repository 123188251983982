import { ButtonBack } from "components/ButtonBack";
import { PageHeader } from "components/PageHeader";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/constants";
import googleAnalytics, { IUseGA } from "src/lib/googleAnalytics";
import * as s from "./styles";
import { ContentLayout } from "components/ContentLayout";
import { Header as CommonHeader } from "components/Header";

const CookiesPageComponent: React.FC = () => {
  const { logPageView }: IUseGA = googleAnalytics();

  const navigateTo = useNavigate();

  const goToBack = useCallback(
    () => navigateTo({ pathname: ROUTES.ROOT, search: window.location.search }),
    [navigateTo]
  );

  useEffect(() => {
    logPageView(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CommonHeader />

      <ContentLayout>
        <div>
          <ButtonBack handlerClick={goToBack}>Back</ButtonBack>
        </div>
        <PageHeader
          title="Use of cookies by Security Watchdog"
          description="Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. The table below explains the cookies we use and why."
        />
        <s.ContentBox>
          <s.PrimaryText>Candidate portal</s.PrimaryText>
          <s.SecondaryText>Google Analytics</s.SecondaryText>
          <s.Text marginBottom={8}>
            These cookies are used to collect information about how visitors use
            our website. We use the information to compile reports and to help
            us improve the website. The cookies collect information in a way
            that does not directly identify anyone, including the number of
            visitors to the website and blog, where visitors have come to the
            website from and the pages they visited.
          </s.Text>
          <s.CustomLink
            isExternal
            to="https://support.google.com/analytics/answer/6004245"
            target="_blank"
          >
            Read Google’s overview of privacy and safeguarding data
          </s.CustomLink>
          <s.Text marginTop={8}>
            We satisfy these rules under in the General Data Protection
            Regulation (GDPR) and the ePrivacy Directive 2009/136/EC (ePR):{" "}
          </s.Text>
          <ul>
            <li>
              <s.Text>
                Prior consent on other than strictly necessary cookies.
              </s.Text>
            </li>
            <li>
              <s.Text>Prior consent on personal data.</s.Text>
            </li>
          </ul>
          <s.PrimaryText>How do I change my cookie settings?</s.PrimaryText>
          <s.Text marginBottom={8}>
            You can change your cookie preferences of your browser. You may need
            to refresh your page for your settings to take effect.
          </s.Text>
          <s.Text>
            Most web browsers allow some control of most cookies through the
            browser settings. To find out more about cookies, including how to
            see what cookies have been set, visit{" "}
            <s.CustomLink isExternal to="https://www.aboutcookies.org/">
              www.aboutcookies.org
            </s.CustomLink>{" "}
            or{" "}
            <s.CustomLink to="https://www.allaboutcookies.org/" isExternal>
              www.allaboutcookies.org
            </s.CustomLink>
          </s.Text>
          <s.Text marginTop={8}>
            Find out how to manage cookies on popular browsers:
          </s.Text>
          <ul>
            <li>
              <s.CustomLink
                isExternal
                to="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en"
              >
                Google Chrome
              </s.CustomLink>
            </li>
            <li>
              <s.CustomLink
                isExternal
                to="https://support.apple.com/en-gb/safari"
              >
                Apple Safari
              </s.CustomLink>
            </li>
            <li>
              <s.CustomLink
                isExternal
                to="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy"
              >
                Microsoft Edge
              </s.CustomLink>
            </li>
            <li>
              <s.CustomLink
                isExternal
                to="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectlocale=en-US&redirectslug=enable-and-disable-cookies-website-preferences"
              >
                Mozilla Firefox
              </s.CustomLink>
            </li>
            <li>
              <s.CustomLink
                isExternal
                to="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
              >
                Microsoft Internet Explorer
              </s.CustomLink>
            </li>
            <li>
              <s.CustomLink
                isExternal
                to="https://help.opera.com/en/latest/web-preferences/"
              >
                Opera
              </s.CustomLink>
            </li>
          </ul>
          <s.Text>
            To find information relating to other browsers, visit the browser
            developer’s website.
          </s.Text>
          <s.Text marginTop={8} marginBottom={8}>
            To opt out of being tracked by Google Analytics across all websites,
            visit{" "}
            <s.CustomLink
              isExternal
              to="https://tools.google.com/dlpage/gaoptout"
            >
              http://tools.google.com/dlpage/gaoptout
            </s.CustomLink>
          </s.Text>
          <s.PrimaryText>Change your settings</s.PrimaryText>
          <s.Text marginBottom={16}>
            You can also use Cookie Settings which you will find in the Cookie
            disclaimer, to adjust your personal settings.
          </s.Text>
          <s.PrimaryText>More information</s.PrimaryText>
          <s.Text>
            Further information about our data processing can be found in our{" "}
            <s.CustomLink
              isExternal
              to="https://www.securitywatchdog.org.uk/privacy-policy"
            >
              Privacy Policy
            </s.CustomLink>
          </s.Text>
        </s.ContentBox>
      </ContentLayout>
    </>
  );
};

export const CookiesPage = React.memo(CookiesPageComponent);

export default CookiesPage;
