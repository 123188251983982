import styled, { css } from "styled-components";
import {
  getTypographyStyles,
  ILinkProps,
  Link
} from "@security-watchdog/sw-ui-kit";
import { IStylesProps } from "./types";

const setMarginForText = ({ marginBottom, marginTop }: IStylesProps) => css`
  margin-top: ${marginTop || 0}px;
  margin-bottom: ${marginBottom || 0}px;
`;

export const ContentBox = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 25px;
  background-color: ${({ theme }): string => theme.colors.white};
  padding: 16px 24px 24px;

  ul {
    padding-left: 32px;
  }
`;

export const PrimaryText = styled.h3`
  margin: 8px 0;
  ${getTypographyStyles({ variant: "20MediumL30" })}
`;

export const SecondaryText = styled.div`
  margin-bottom: 8px;
  ${getTypographyStyles({ variant: "16MediumL24" })}
`;

export const Text = styled.div<IStylesProps>`
  ${setMarginForText};
  ${getTypographyStyles({ variant: "16RegularL24" })};
`;

export const CustomLink = styled(Link)<ILinkProps>`
  ${getTypographyStyles({ color: "royalBlue", variant: "16RegularL24" })};
  color: var(--color-text-on-surface);
  border-bottom: 1px solid var(--color-divider-default);
`;
