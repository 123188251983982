import { OptionProps } from "@security-watchdog/sw-ui-kit";
import { v4 } from "uuid";
import {
  fieldAnyValueFragment_QualificationsAndGrades_qualificationsAndGrades,
  fieldAnyValueFragment_QualificationsAndGrades_qualificationsAndGrades_grades,
  fieldRestrictionFragment_SelectRestriction_values,
  FieldType,
  getCase_screeningCase_products,
  getCase_screeningCase_products_product_conditionalQuestions,
  getCase_screeningCase_products_product_conditionalQuestions_conditions,
  getCase_screeningCase_sections_answers,
  getCase_screeningCase_sections_answers_answers,
  getCase_screeningCase_sections_answers_answers_fieldValues,
  getCase_screeningCase_sections_answers_answers_fieldValues_value,
  getCase_screeningCase_sections_answers_question_conditionalElements,
  getCase_screeningCase_sections_answers_question_conditionalElements_conditions,
  getCase_screeningCase_sections_answers_question_conditionalElements_fields,
  getCase_screeningCase_sections_answers_question_fields,
  getCase_screeningCase_sections_answers_question_fields_opsFields,
  getCase_screeningCase_sections_answers_question_fields_restrictions,
  HandleableFieldType,
  OpsEbulkFieldType
} from "../graphQLTypes";
import {
  IAnswer,
  ICondition,
  IDependentField,
  IFieldAnswer,
  IFieldConditionsMap,
  IFieldsThatHaveDependentFieldsMapMap,
  IFieldValue,
  IFieldValuesMap,
  IQuestion,
  IValidation
} from "../types";
import {
  isAddressValue,
  isAttachmentsValue,
  isAttachmentValue,
  isBooleanValue,
  isCompanyValue,
  isCountryValue,
  isDateRangeValue,
  isDateRestriction,
  isDateValue,
  isDurationRestriction,
  isFloatValue,
  isGapRestriction,
  isLengthRestriction,
  isNumericRestriction,
  isOcrFieldValue,
  isOpsEbulkFieldValue,
  isQualificationAndGradesValues,
  isSelectRestriction,
  isStringValue,
  isStringValues,
  isUndefinedOrNull
} from "./typeGuards";
import { checkConditions } from "./checkConditionValue";
import { checkEntityIds } from "./checkEntityIds";
import {
  DraftAnswerVales,
  DraftQuestionValue,
  DraftQuestionValues,
  DraftSectionValues
} from "./buildInputForSaveDraftValues";
import { isGeneralUploadField } from "src/utils/answers";

const getDateWithoutTimezone = (
  dateString: string | null
): Date | string | null => {
  if (dateString) {
    try {
      const date = new Date(dateString);

      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    } catch {
      return null;
    }
  }

  return dateString;
};

const getValueByValueType = (
  value: getCase_screeningCase_sections_answers_answers_fieldValues_value
): IFieldValue => {
  if (isStringValue(value)) {
    return value.stringValue;
  }
  if (isFloatValue(value)) {
    return value.floatValue;
  }
  if (isBooleanValue(value)) {
    return value.booleanValue;
  }
  if (isDateValue(value)) {
    return value.dateValue ? new Date(value.dateValue) : value.dateValue;
  }
  if (isDateRangeValue(value)) {
    return {
      from: getDateWithoutTimezone(value.from),
      to: getDateWithoutTimezone(value.to)
    };
  }
  if (isCompanyValue(value)) {
    return {
      name: value.name,
      addressLine1: value.address.addressLine1,
      addressLine2: value.address.addressLine2,
      addressLine3: value.address.addressLine3,
      country: value.address.country,
      county: value.address.county,
      postcode: value.address.postcode,
      town: value.address.town
    };
  }
  if (
    isAttachmentValue(value) ||
    isCountryValue(value) ||
    isAddressValue(value)
  ) {
    return value;
  }
  if (isAttachmentsValue(value)) {
    return value.attachmentsValue;
  }
  if (isStringValues(value)) {
    return value.stringValues;
  }

  if (isQualificationAndGradesValues(value)) {
    return value.qualificationsAndGrades.map(
      (
        qualificationAndGrade: fieldAnyValueFragment_QualificationsAndGrades_qualificationsAndGrades
      ) => ({
        ...qualificationAndGrade,
        id: v4(),
        grades: qualificationAndGrade.grades.map(
          (
            grade: fieldAnyValueFragment_QualificationsAndGrades_qualificationsAndGrades_grades
          ) => ({
            ...grade,
            id: v4()
          })
        )
      })
    );
  }

  return undefined;
};

const getSelectOptions = (
  restrictions: getCase_screeningCase_sections_answers_question_fields_restrictions[]
): Array<OptionProps<string>> => {
  const selectRestriction = restrictions.find(isSelectRestriction);
  return (
    selectRestriction?.values.map(
      ({
        id,
        title
      }: fieldRestrictionFragment_SelectRestriction_values): OptionProps<string> => ({
        id,
        value: id,
        label: title.current
      })
    ) || []
  );
};

const getSelectIsMulti = (
  restrictions: getCase_screeningCase_sections_answers_question_fields_restrictions[]
): boolean => {
  const selectRestriction = restrictions.find(isSelectRestriction);
  return selectRestriction ? selectRestriction.multiSelect : false;
};

const getDependentField = (
  fields: getCase_screeningCase_sections_answers_question_fields[]
): IDependentField | undefined => {
  const dependentField:
    | getCase_screeningCase_sections_answers_question_fields
    | undefined = fields.find(
    (field: getCase_screeningCase_sections_answers_question_fields) =>
      field.type === FieldType.BOOLEAN &&
      (field.fieldType === HandleableFieldType.CURRENT_EMPLOYER ||
        field.fieldType === HandleableFieldType.CURRENT_ADDRESS)
  );

  if (dependentField) {
    const isMulti: boolean =
      dependentField.type === FieldType.SELECT ||
      dependentField.type === FieldType.QUALIFICATIONS_AND_GRADES
        ? getSelectIsMulti(dependentField.restrictions)
        : false;

    return {
      isMulti,
      fieldId: dependentField.id,
      fieldType: dependentField.fieldType,
      type: dependentField.type
    };
  }
  return undefined;
};

const getFieldValidation = (
  field: getCase_screeningCase_sections_answers_question_fields,
  fields: getCase_screeningCase_sections_answers_question_fields[],
  isTimelineSection: boolean,
  isMulti: boolean
): IValidation =>
  field.restrictions.reduce(
    (
      acc: IValidation,
      restriction: getCase_screeningCase_sections_answers_question_fields_restrictions
    ) => {
      if (isDateRestriction(restriction)) {
        return {
          ...acc,
          dateRestrictions: acc.dateRestrictions
            ? [...acc.dateRestrictions, restriction]
            : [restriction]
        };
      }
      if (isDurationRestriction(restriction)) {
        return {
          ...acc,
          durationRestriction: restriction
        };
      }
      if (isGapRestriction(restriction)) {
        return {
          ...acc,
          gapRestriction: restriction
        };
      }
      if (isLengthRestriction(restriction)) {
        return {
          ...acc,
          lengthRestriction: restriction
        };
      }
      if (isNumericRestriction(restriction)) {
        return {
          ...acc,
          numericRestrictions: acc.numericRestrictions
            ? [...acc.numericRestrictions, restriction]
            : [restriction]
        };
      }
      return acc;
    },
    {
      isMulti,
      isRequired: field.required,
      dependentField:
        isTimelineSection && field.type === FieldType.DATE_RANGE
          ? getDependentField(fields)
          : undefined
    }
  );

const checkRoleLocationScotland = (
  opsFields: getCase_screeningCase_sections_answers_question_fields_opsFields[]
): boolean =>
  opsFields.some(
    (
      opsField: getCase_screeningCase_sections_answers_question_fields_opsFields
    ) =>
      isOpsEbulkFieldValue(opsField) &&
      opsField.ebulkValue === OpsEbulkFieldType.ROLE_LOCATION_IS_SCOTLAND
  );

const checkIsOcrField = (
  opsFields: getCase_screeningCase_sections_answers_question_fields_opsFields[]
): boolean =>
  opsFields.some(
    (
      opsField: getCase_screeningCase_sections_answers_question_fields_opsFields
    ) => isOcrFieldValue(opsField) && opsField.ocrValue
  );

const getFields = (
  fields: getCase_screeningCase_sections_answers_question_fields[],
  fieldConditionsMap: IFieldConditionsMap,
  fieldsThatHaveDependentFieldsMap: IFieldsThatHaveDependentFieldsMapMap,
  isTimelineSection: boolean,
  answerIndex: number
): IFieldAnswer[] =>
  fields.map(
    (
      field: getCase_screeningCase_sections_answers_question_fields
    ): IFieldAnswer => {
      const isMulti: boolean =
        field.type === FieldType.SELECT ||
        field.type === FieldType.QUALIFICATIONS_AND_GRADES
          ? getSelectIsMulti(field.restrictions)
          : false;

      return {
        answerIndex,
        isHighlightedWhenOcrUploaded: checkIsOcrField(field.opsFields),
        fieldId: field.id,
        title: field.title.current,
        description: field.description.current,
        type: field.type,
        fieldType: field.fieldType,
        isConditional: field.conditional,
        isVisible: Boolean(!fieldConditionsMap[field.id]),
        options:
          field.type === FieldType.SELECT ||
          field.type === FieldType.QUALIFICATIONS_AND_GRADES
            ? getSelectOptions(field.restrictions)
            : [],
        value: undefined,
        hasDependentFields: Boolean(fieldsThatHaveDependentFieldsMap[field.id]),
        conditions: fieldConditionsMap[field.id] || [],
        isRoleLocationScotland: checkRoleLocationScotland(field.opsFields),
        validation: getFieldValidation(
          field,
          fields,
          isTimelineSection,
          isMulti
        ),
        isMulti
      };
    }
  );

export const getFieldValuesMap = (
  fieldValues: getCase_screeningCase_sections_answers_answers_fieldValues[]
): IFieldValuesMap =>
  fieldValues.reduce(
    (
      acc: IFieldValuesMap,
      fieldValue: getCase_screeningCase_sections_answers_answers_fieldValues
    ): IFieldValuesMap => ({
      ...acc,
      [fieldValue.field.id]: fieldValue.value
        ? getValueByValueType(fieldValue.value)
        : undefined
    }),
    {}
  );

const getFiledSkipValueMap = (
  fieldValues: getCase_screeningCase_sections_answers_answers_fieldValues[]
): IFieldValuesMap =>
  fieldValues.reduce(
    (
      acc: IFieldValuesMap,
      fieldValue: getCase_screeningCase_sections_answers_answers_fieldValues
    ): IFieldValuesMap => ({
      ...acc,
      [fieldValue.field.id]: fieldValue.skip
    }),
    {}
  );

const getAnswers = (
  fields: IFieldAnswer[],
  answers: getCase_screeningCase_sections_answers_answers[]
): IAnswer[] =>
  answers.map(
    (
      answer: getCase_screeningCase_sections_answers_answers,
      index: number
    ): IAnswer => {
      const fieldValuesMap: IFieldValuesMap = getFieldValuesMap(
        answer.fieldValues
      );

      const fieldSkipValueMap: IFieldValuesMap = getFiledSkipValueMap(
        answer.fieldValues
      );

      return {
        index,
        isCandidateAnswer: answer.isCandidateAnswer,
        answerId: answer.id,
        isAnswered: true,
        fields: fields.map(
          (field: IFieldAnswer): IFieldAnswer => ({
            ...field,
            answerIndex: index,
            value: fieldValuesMap[field.fieldId],
            isAnswered: !isUndefinedOrNull(fieldValuesMap[field.fieldId]),
            isVisible: fieldSkipValueMap[field.fieldId]
              ? false
              : checkConditions(field.conditions, fieldValuesMap)
          })
        )
      };
    }
  );

export const getQuestionConditionsMap = (
  conditionalQuestion:
    | getCase_screeningCase_products_product_conditionalQuestions
    | undefined
): IFieldConditionsMap => {
  if (conditionalQuestion) {
    const conditions: ICondition[] = conditionalQuestion.conditions.map(
      (
        condition: getCase_screeningCase_products_product_conditionalQuestions_conditions
      ) => {
        const isMulti: boolean =
          condition.fieldValue.field.type === FieldType.SELECT ||
          condition.fieldValue.field.type ===
            FieldType.QUALIFICATIONS_AND_GRADES
            ? getSelectIsMulti(condition.fieldValue.field.restrictions)
            : false;

        return {
          isMulti,
          fieldId: condition.fieldValue.field.id,
          value: condition.fieldValue.value
            ? getValueByValueType(condition.fieldValue.value)
            : undefined,
          type: condition.fieldValue.field.type,
          fieldType: condition.fieldValue.field.fieldType
        };
      }
    );
    return {
      [conditionalQuestion.question.id]: [conditions]
    };
  }

  return {};
};

export const getFieldConditionsMap = (
  conditionalElements: getCase_screeningCase_sections_answers_question_conditionalElements[]
): IFieldConditionsMap =>
  conditionalElements.reduce(
    (
      acc: IFieldConditionsMap,
      element: getCase_screeningCase_sections_answers_question_conditionalElements
    ) => {
      const conditions: ICondition[] = element.conditions.map(
        (
          condition: getCase_screeningCase_sections_answers_question_conditionalElements_conditions
        ): ICondition => {
          const isMulti: boolean =
            condition.fieldValue.field.type === FieldType.SELECT ||
            condition.fieldValue.field.type ===
              FieldType.QUALIFICATIONS_AND_GRADES
              ? getSelectIsMulti(condition.fieldValue.field.restrictions)
              : false;

          return {
            isMulti,
            fieldId: condition.fieldValue.field.id,
            value: condition.fieldValue.value
              ? getValueByValueType(condition.fieldValue.value)
              : undefined,
            type: condition.fieldValue.field.type,
            fieldType: condition.fieldValue.field.fieldType
          };
        }
      );
      const fieldsMap: IFieldConditionsMap = element.fields.reduce(
        (
          fieldAcc: IFieldConditionsMap,
          field: getCase_screeningCase_sections_answers_question_conditionalElements_fields
        ): IFieldConditionsMap => ({
          ...fieldAcc,
          [field.id]: acc[field.id]
            ? [...acc[field.id], conditions]
            : [conditions]
        }),
        {}
      );
      return {
        ...acc,
        ...fieldsMap
      };
    },
    {}
  );

const getFieldsThatHaveDependentFieldsMap = (
  conditionalElements: getCase_screeningCase_sections_answers_question_conditionalElements[]
): IFieldsThatHaveDependentFieldsMapMap =>
  conditionalElements.reduce(
    (
      acc: IFieldsThatHaveDependentFieldsMapMap,
      element: getCase_screeningCase_sections_answers_question_conditionalElements
    ) => ({
      ...acc,
      ...element.conditions.reduce(
        (
          acc: IFieldsThatHaveDependentFieldsMapMap,
          condition: getCase_screeningCase_sections_answers_question_conditionalElements_conditions
        ) => ({
          ...acc,
          [condition.fieldValue.field.id]: condition.fieldValue.field.id
        }),
        {}
      )
    }),
    {}
  );

export const getConditionalQuestion = (
  products: getCase_screeningCase_products[],
  questionId: string
): getCase_screeningCase_products_product_conditionalQuestions | undefined =>
  products.reduce<
    getCase_screeningCase_products_product_conditionalQuestions | undefined
  >(
    (
      acc:
        | getCase_screeningCase_products_product_conditionalQuestions
        | undefined,
      { product }: getCase_screeningCase_products
    ):
      | getCase_screeningCase_products_product_conditionalQuestions
      | undefined => {
      const conditionalQuestion = product.conditionalQuestions.find(
        (
          conditionalQuestion: getCase_screeningCase_products_product_conditionalQuestions
        ) => checkEntityIds(conditionalQuestion.question.id, questionId)
      );

      return conditionalQuestion || acc;
    },
    undefined
  );

export const prepareDraftAnswers = (
  fields: IFieldAnswer[],
  draftAnswersMap: DraftQuestionValues
): IAnswer[] => {
  const answerIds: string[] = draftAnswersMap
    ? Object.keys(draftAnswersMap)
    : [];

  return answerIds.map((answerId: string): IAnswer => {
    const answerFieldsMap: DraftAnswerVales | undefined =
      draftAnswersMap?.[answerId].fields;

    const index: ScalarLong = draftAnswersMap?.[answerId].index || 0;

    const updatedFields: IFieldAnswer[] = fields.map((field: IFieldAnswer) => {
      const value = answerFieldsMap?.[field.fieldId];

      return {
        ...field,
        value,
        isAnswered: Boolean(value),
        isVisible: checkConditions(field.conditions, answerFieldsMap || {})
      };
    });

    return {
      index,
      answerId,
      isAnswered: draftAnswersMap[answerId]?.isAnswered || false,
      isDeleted: draftAnswersMap[answerId]?.isDeleted || false,
      fields: updatedFields.map((field: IFieldAnswer) => ({
        ...field,
        answerIndex: index
      }))
    };
  });
};

const mergeAnswers = (
  answeredAnswers: IAnswer[],
  draftAnswers: IAnswer[]
): IAnswer[] => {
  const map = new Map<string, IAnswer>();
  const arr = [...answeredAnswers, ...draftAnswers];

  // eslint-disable-next-line no-restricted-syntax
  for (const obj of arr) {
    if (!map.has(obj.answerId)) {
      map.set(obj.answerId, obj);
    } else {
      const answerObj: IAnswer | undefined = map.get(obj.answerId);

      map.set(obj.answerId, {
        ...map.get(obj.answerId),
        ...obj,
        isDeleted: answerObj?.isDeleted || obj.isDeleted,
        isAnswered: answerObj?.isAnswered || obj.isAnswered,
        index: answerObj?.index || 0
      });
    }
  }

  return Array.from(map.values());
};

export interface ICheckPartiallySavedAnswers {
  draftAnswersMap?: DraftQuestionValues;
  answers: IAnswer[];
}

export const checkPartiallySavedAnswers = (
  fields: IFieldAnswer[],
  isMultipleAnswers: boolean,
  answers: IAnswer[],
  draftAnswersMap?: DraftQuestionValues
): ICheckPartiallySavedAnswers => {
  if (
    !isMultipleAnswers &&
    answers.length &&
    draftAnswersMap &&
    Object.keys(draftAnswersMap).length
  ) {
    const savedAnswerId: string = answers[0].answerId;
    const draftAnswerId: string = Object.keys(draftAnswersMap)[0];
    const draftAnswer = draftAnswersMap[draftAnswerId];

    if (savedAnswerId !== draftAnswerId && draftAnswer) {
      return {
        answers
      };
    }

    return {
      answers,
      draftAnswersMap
    };
  }

  return {
    answers,
    draftAnswersMap
  };
};

export const createAnswers = (
  fields: IFieldAnswer[],
  answer: getCase_screeningCase_sections_answers,
  isTimelineSection: boolean,
  isMultipleAnswers: boolean,
  draftAnswersMap?: DraftQuestionValues
): IAnswer[] => {
  const draftAnswers: IAnswer[] = prepareDraftAnswers(
    fields,
    draftAnswersMap || {}
  );

  // eslint-disable-next-line no-nested-ternary
  const answeredAnswers: IAnswer[] = answer.answers.length
    ? getAnswers(fields, answer.answers)
    : // eslint-disable-next-line no-nested-ternary
      isTimelineSection
      ? []
      : draftAnswers.length
        ? []
        : [
            {
              answerId: v4(),
              isAnswered: false,
              index: 0,
              fields: fields.map((field: IFieldAnswer) => ({
                ...field,
                answerIndex: 0
              }))
            }
          ];

  const data: ICheckPartiallySavedAnswers = checkPartiallySavedAnswers(
    fields,
    isMultipleAnswers,
    answeredAnswers,
    draftAnswersMap
  );

  return mergeAnswers(
    data.answers,
    prepareDraftAnswers(fields, data.draftAnswersMap || {})
  );
};

export const getQuestionVisibility = (
  answer: getCase_screeningCase_sections_answers,
  draftAnswersMap: DraftQuestionValue | null
): boolean =>
  answer.conditionFulfilled ||
  !answer.question.conditional ||
  Boolean(draftAnswersMap?.isVisible);

export const getQuestionsOfSection = ({
  answers,
  isTimelineSection = false,
  products,
  filterUploadFields = false,
  draftData
}: {
  answers: getCase_screeningCase_sections_answers[] | undefined;
  isTimelineSection: boolean;
  products: getCase_screeningCase_products[];
  filterUploadFields: boolean;
  draftData?: DraftSectionValues | null;
}): IQuestion[] => {
  if (!answers) {
    return [];
  }
  return answers.reduce(
    (
      acc: IQuestion[],
      answer: getCase_screeningCase_sections_answers
    ): IQuestion[] => {
      const conditionalQuestion:
        | getCase_screeningCase_products_product_conditionalQuestions
        | undefined = getConditionalQuestion(products, answer.question.id);

      const questionConditionsMap: IFieldConditionsMap =
        getQuestionConditionsMap(conditionalQuestion);

      const fieldConditionsMap: IFieldConditionsMap = getFieldConditionsMap(
        answer.question.conditionalElements
      );

      const fieldsThatHaveDependentFieldsMap: IFieldsThatHaveDependentFieldsMapMap =
        getFieldsThatHaveDependentFieldsMap(
          answer.question.conditionalElements
        );

      const fields: IFieldAnswer[] = getFields(
        filterUploadFields
          ? answer.question.fields.filter(
              (field: getCase_screeningCase_sections_answers_question_fields) =>
                !isGeneralUploadField(field.type)
            )
          : answer.question.fields,
        fieldConditionsMap,
        fieldsThatHaveDependentFieldsMap,
        isTimelineSection,
        0
      );

      const draftAnswersMap: DraftQuestionValue | null = draftData
        ? draftData[answer.question.id]
        : null;

      return [
        ...acc,
        {
          isVisible: getQuestionVisibility(answer, draftAnswersMap),
          conditionFulfilled: answer.conditionFulfilled,
          conditional: answer.question.conditional,
          questionId: answer.question.id,
          title: answer.question.title,
          questionType: answer.question.questionType,
          isMultipleAnswers: answer.question.multipleAnswers,
          questionConditionsMap,
          fields,
          answers: createAnswers(
            fields,
            answer,
            isTimelineSection,
            answer.question.multipleAnswers,
            draftAnswersMap?.answers || {}
          )
        }
      ];
    },
    []
  );
};
