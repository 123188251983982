import AsyncValue from "src/utils/AsyncValue";
import { appConfig } from "./appConfig";
import getMappedAzureConfig from "./getMappedAzureConfig";

export type AzureAppConfig = {
  // Azure Vars

  // candidate azure b2c auth
  AZURE_CLIENT_ID: string;
  AZURE_AUTHORITY_DOMAIN: string;
  AZURE_SIGN_UP_OR_SIGN_IN_NAME: string;
  AZURE_SIGN_IN_NAME: string;
  AZURE_SIGN_UP_NAME: string;
  AZURE_RESET_NAME: string;

  // screener azure b2c auth
  AZURE_SCREENER_B2C_AUTHORITY_DOMAIN: string;
  AZURE_SCREENER_B2C_CLIENT_ID: string;
  AZURE_SCREENER_B2C_SIGN_IN_NAME: string;

  CHAT_BOT_LICENSE_NUMBER: string;
  LIVE_CHAT_LICENSE_NUMBER: string;

  CLARITY_KEY: string;

  // other one
  SURVICATE_SURVEY_ID: string;
  SURVICATE_WORKSPACE_KEY: string;
  CRAFTY_CLICKS_AUTH_KEY: string;
  GOOGLE_ANALYTICS_TRACKING_ID: string;
  featureToggle: {
    ENABLE_CONDITIONAL_PRODUCTS_SUBMIT: boolean;
    ENABLE_TRUST_ID_INTEGRATED_PRODUCT: boolean;
    ENABLE_CANDIDATE_SELF_SERVICE: boolean;
    ENABLE_CHAT_BOT_LIVE_CHAT: boolean;
    ENABLE_KONFIR_INTEGRATION: boolean;
    FT_ENABLE_CASE_MANAGER_ASSIGNMENT: boolean;
    ENABLE_CLARITY: boolean;
  };
};

export const azureAppConfigInstance = new AsyncValue<AzureAppConfig>(
  async () => {
    const serviceEnvironment = appConfig.SERVICE_ENVIRONMENT.toLowerCase();
    const connectionStrings =
      appConfig.AZURE_APP_CONFIGURATION_CONNECTION_STRING.split(",").map(
        (connectionString) => connectionString.trim()
      );

    return await getMappedAzureConfig(
      connectionStrings,
      serviceEnvironment,
      mapAzureFeatureFlagKey,
      mapAzureConfigKey
    );
  }
);

export class AzureConfig {
  config: AzureAppConfig;
  constructor() {
    this.config = {
      AZURE_CLIENT_ID: "",
      AZURE_AUTHORITY_DOMAIN: "",
      AZURE_SIGN_UP_OR_SIGN_IN_NAME: "",
      AZURE_SIGN_IN_NAME: "",
      AZURE_SIGN_UP_NAME: "",
      AZURE_RESET_NAME: "",
      AZURE_SCREENER_B2C_AUTHORITY_DOMAIN: "",
      AZURE_SCREENER_B2C_CLIENT_ID: "",
      AZURE_SCREENER_B2C_SIGN_IN_NAME: "",
      SURVICATE_SURVEY_ID: "",
      SURVICATE_WORKSPACE_KEY: "",
      CRAFTY_CLICKS_AUTH_KEY: "",
      GOOGLE_ANALYTICS_TRACKING_ID: "",
      CHAT_BOT_LICENSE_NUMBER: "",
      LIVE_CHAT_LICENSE_NUMBER: "",
      CLARITY_KEY: "",
      featureToggle: {
        ENABLE_CONDITIONAL_PRODUCTS_SUBMIT: false,
        ENABLE_TRUST_ID_INTEGRATED_PRODUCT: false,
        ENABLE_CANDIDATE_SELF_SERVICE: false,
        ENABLE_CHAT_BOT_LIVE_CHAT: false,
        ENABLE_KONFIR_INTEGRATION: false,
        FT_ENABLE_CASE_MANAGER_ASSIGNMENT: false,
        ENABLE_CLARITY: false
      }
    };
  }
}

function mapAzureFeatureFlagKey(
  key: string
): keyof AzureAppConfig["featureToggle"] | null {
  switch (key) {
    case "ENABLE_TRUST_ID_INTEGRATED_PRODUCT": {
      return "ENABLE_TRUST_ID_INTEGRATED_PRODUCT";
    }
    case "ENABLE_CHAT_BOT_LIVE_CHAT_ON_CANDIDATE": {
      return "ENABLE_CHAT_BOT_LIVE_CHAT";
    }

    case "ENABLE_CANDIDATE_SELF_SERVICE": {
      return "ENABLE_CANDIDATE_SELF_SERVICE";
    }

    case "CANDIDATE_PORTAL_FT_ENABLE_CONDITIONAL_PRODUCTS_SUBMIT": {
      return "ENABLE_CONDITIONAL_PRODUCTS_SUBMIT";
    }

    case "ENABLE_KONFIR_INTEGRATION": {
      return "ENABLE_KONFIR_INTEGRATION";
    }

    case "FT_ENABLE_CASE_MANAGER_ASSIGNMENT": {
      return "FT_ENABLE_CASE_MANAGER_ASSIGNMENT";
    }

    case "CANDIDATE_PORTAL_ENABLE_CLARITY": {
      return "ENABLE_CLARITY";
    }

    default: {
      return null;
    }
  }
}

function mapAzureConfigKey(
  key: string
): Exclude<keyof AzureAppConfig, "featureToggle"> | null {
  switch (key) {
    case "CANDIDATE_PORTAL_SURVICATE_SURVEY_ID": {
      return "SURVICATE_SURVEY_ID";
    }

    case "CANDIDATE_PORTAL_SURVICATE_WORKSPACE_KEY": {
      return "SURVICATE_WORKSPACE_KEY";
    }

    case "CRAFTY_CLICKS_AUTH_KEY": {
      return "CRAFTY_CLICKS_AUTH_KEY";
    }

    case "CANDIDATE_PORTAL_GOOGLE_ANALYTICS_TRACKING_ID": {
      return "GOOGLE_ANALYTICS_TRACKING_ID";
    }

    case "CANDIDATE_PORTAL_AZURE_CLIENT_ID": {
      return "AZURE_CLIENT_ID";
    }

    case "CANDIDATE_PORTAL_AZURE_AUTHORITY_DOMAIN": {
      return "AZURE_AUTHORITY_DOMAIN";
    }

    case "CANDIDATE_PORTAL_AZURE_SIGN_UP_OR_SIGN_IN_NAME": {
      return "AZURE_SIGN_UP_OR_SIGN_IN_NAME";
    }

    case "CANDIDATE_PORTAL_AZURE_SIGN_IN_NAME": {
      return "AZURE_SIGN_IN_NAME";
    }

    case "CANDIDATE_PORTAL_AZURE_SIGN_UP_NAME": {
      return "AZURE_SIGN_UP_NAME";
    }

    case "CANDIDATE_PORTAL_AZURE_RESET_NAME": {
      return "AZURE_RESET_NAME";
    }

    case "CANDIDATE_PORTAL_CHAT_BOT_LICENSE_NUMBER": {
      return "CHAT_BOT_LICENSE_NUMBER";
    }

    case "CANDIDATE_PORTAL_LIVE_CHAT_LICENSE_NUMBER": {
      return "LIVE_CHAT_LICENSE_NUMBER";
    }

    case "SCREENER_PORTAL_AZURE_AUTHORITY_DOMAIN": {
      return "AZURE_SCREENER_B2C_AUTHORITY_DOMAIN";
    }

    case "SCREENER_PORTAL_AZURE_CLIENT_ID": {
      return "AZURE_SCREENER_B2C_CLIENT_ID";
    }

    case "SCREENER_PORTAL_AZURE_SIGN_IN_NAME": {
      return "AZURE_SCREENER_B2C_SIGN_IN_NAME";
    }

    case "CANDIDATE_PORTAL_CLARITY_KEY": {
      return "CLARITY_KEY";
    }

    default: {
      return null;
    }
  }
}
