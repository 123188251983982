import React, { FC, memo } from "react";
import { Helmet } from "react-helmet";
import { isClarityEnabled } from "src/utils";
import { azureAppConfigVar } from "src/cache";

const Component: FC = () =>
  isClarityEnabled() ? (
    <Helmet>
      <script type="text/javascript">
        {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", ${JSON.stringify(azureAppConfigVar().CLARITY_KEY)});
        `}
      </script>
    </Helmet>
  ) : null;

Component.displayName = "MicrosoftClarity";

export const MicrosoftClarity = memo(Component);
