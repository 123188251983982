import styled from "styled-components";
import {
  getOutlineStyles,
  getTypographyStyles
} from "@security-watchdog/sw-ui-kit";

export const NoteContainer = styled.div`
  display: flex;
  overflow-y: auto;
  margin: 4px 0 8px;
  max-height: 134px;
  word-break: break-word;
  white-space: break-spaces;
`;

export const Container = styled.div`
  margin: 8px 0 24px;
`;

export const ScreenerInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  margin-bottom: 24px;
  border-bottom: ${({ theme }): string => `1px solid ${theme.colors.snuff}`};
`;

export const FileNameContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  background-color: ${({ theme }): string => theme.colors.whiteLilac};
  margin-top: 16px;
`;

export const FileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
  flex-grow: 1;
  flex-basis: 0;
  cursor: pointer;

  &:focus {
    ${getOutlineStyles()}
  }
`;

export const DocumentsLengthWarning = styled.div`
  ${getTypographyStyles({ variant: "14RegularL20", color: "mojo" })};
  margin: 8px 0;
`;
